<template>
  <div>
    <button v-for="btn in actions" :class="btn.classButton" :key="btn.key" @click="emitEvent(btn.event, item)">
        <CIcon v-if="btn.type === 'icon'" :name="btn.icon"/>
        <span v-else>{{ btn.label }}</span>
    </button>
  </div>
</template>

<script>
export default {
    name: 'AbstractButton',
    props: {
        actions: {
            type: Array,
            required: true
        },
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        /** 
        * Evento que se emite al padre al pulsar el botón, recoge el nombre del método de manera dinámica
        */
        emitEvent(eventName, item) {
            this.$emit('buttonEvent', eventName, item);
        }
    }
}
</script>

<style scoped>

</style>