/** 
 * Función para limitar caracteres
 */
export function limitCharacters(text, limit){
    if (text.length > limit) {
        return text.substring(0, limit) + "...";
    }
    return text;
}
/** 
 * Función para generar los campos de las tablas
 */
export function generateField(key = "", label = "", options = {}) {
    let {
        sorter = true,
        filter = true,
        show = true,
        type = "text",
        styleHeader = {},
        styleData = {}
    } = options;

    return {
        key, label, sorter, filter, show, type,
        'styleHeader': {
            ...styleHeader,
            verticalAlign: styleHeader?.verticalAlign || 'center',
            textAlign: styleHeader?.textAlign || 'left',
            backGround: styleHeader?.backGround || '#fff',
            color: styleHeader?.color || '#000',
            fontSize: styleHeader?.fontSize || 'inherit',
            fontWeight: styleHeader?.fontWeight || 'inherit',
        },
        'styleData': {
            ...styleData,
            verticalAlign: styleData?.verticalAlign || 'center',
            textAlign: styleData?.textAlign || 'left',
            backGround: styleData?.backGround || '#fff',
            color: styleData?.color || '#000',
            fontSize: styleData?.fontSize || 'inherit',
            fontWeight: styleData?.fontWeight || 'inherit',
        }
    }
}
/** 
 * Esta función que se utiliza para verificar si un elemento está en un array
 */
export function isInArray(item, array) { 
    return array.some(element => element.id === item.id); 
}
