<template>
  <CModal class="c-modal" :title="modalContent.title" color="principal" :show.sync="internalShowModal" :closeOnBackdrop="false">
        <p class="text-muted" v-html="modalContent.textBody"></p>
        <div slot="footer">
            <CButton :color="btn.color" v-for="(btn, index) in modalContent.buttons" :key="index" @click="emitEvent(btn.event)" style="margin-right:5px;">
              {{ btn.textButton }}
            </CButton>
        </div>
  </CModal>
</template>

<script>
export default {
    name: 'DeleteModal',
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
        modalContent: {
            type: Object,
            required: true,
            default: {
                title: 'Título',
                textBody: 'Texto del modal.',
                buttons: [
                    {
                        textButton: 'Botón',
                        color: 'primary'
                    }
                ]
            }
        }
    },
    data(){
        return{
            internalShowModal: false,
        }
    },
    watch: {
    /**
     * Esta propiedad recoge el valor para mostrar el modal.
     */
    showModal(newVal) {
      this.internalShowModal = newVal;
    }
  },
    methods: {
        /**
         * Evento que se emite al padre al pulsar el botón, recoge el nombre del método de manera dinámica
         */
        emitEvent(eventName, item) {
            this.$emit(eventName, item);
        }
    }
}
</script>

<style>
.c-modal .close{
    visibility: hidden;
}
</style>