<template>
    <CCard>
        <CCardHeader>
            <div class="d-flex justify-content-between">
                {{ title }}
                <CButton v-if="addBtn.enable" class="btn btn-add" size="sm" @click="emitEvent(addBtn.event)">
                    {{ addBtn.text || 'Añadir un nuevo elemento' }}
                </CButton>
            </div>
        </CCardHeader>

        <CCardBody>
            <CDataTable hover striped columnFilter 
                :fields="getFields" 
                :items="items" 
                :items-per-page="8"
                :sorter="{ resetable: true }"
                :items-per-page-select="{ label: `${labelItemPerPage}`, values: [5, 10, 25, 50, 100, 250, 500] }"
                :table-filter="{ label: 'Filtrar todo el contenido:', placeholder: 'Comience a escribir ...' }"
                :no-items-view="{ noResults: noResults, noItems: 'No se han encontrado datos que cumplan las características indicadas' }"
                pagination>

                <template v-for="column in fields" v-slot:[column.key]="{ item }">

                    <td :style="getStyleData(column.styleData)" :key="column.key" :class="column.key.includes('description') ? 'td-description': ''" v-c-tooltip="(column.key.includes('description') ? {content: limitField(item[column.key], 100), placement: 'right'} : '')">
                        <template v-if="column.type === 'icon'">
                            <CIcon :name="item.icon" />
                        </template>

                        <template v-else-if="column.type === 'image'">
                            <img :src="item.image" :alt="column.label" />
                        </template>

                        <template v-else>
                            <span>{{ item[column.key] }}</span>
                        </template>
                    </td>
                    
                </template>

                <template #actions="{ item }">
                    <td>
                        <AbstractButton :actions="filteredActions" :item="item" @buttonEvent="emitEvent"/>
                    </td>
                </template>

            </CDataTable>
        </CCardBody>
    </CCard>
</template>

<script>
import AbstractButton from '@/components/Buttons/AbstractButton.vue'
import { limitCharacters } from '@/utils/utils';
export default {
    name: 'AbstractTable',
    components: { AbstractButton },
    props: {
        fields: {
            type: Array,
            required: true,
        },
        actions: {
            type: Array,
            required: false,
        },
        items: {
            type: Array,
            required: true,
        },
        // OPTIONAL VALUES
        noResults: {
            type: String,
            default: "No se han encontrado datos"
        },

        addBtn: {
            type: Object,
            default: { enable: false }
        },
        title: {
            type: String,
            default: 'Tabla de contenido'
        },
        labelItemPerPage: {
            type: String,
            default: 'Elementos por página'
        }
    },
    data() {
        return {}
    },
    computed: {
        /** 
        * Propiedad computada, muestra los botones correspondientes si el usuario es admin o no.
        */
        filteredActions() {
            return this.actions.filter(btn => !btn.isAdmin);
        },
        /** 
        * Propiedad computada, muestra los campos de la tabla filtrados.
        */
        getFields(){
            return this.fields.filter(element => element.show);
        }
    },
    methods: {
        limitField(field, max){
          return limitCharacters(field, max)
        },
        /** 
         * Método para aplicar estilos a los td de la tabla
        */
        getStyleData(style) {
            return {
                verticalAlign: style?.verticalAlign || 'center',
                textAlign: style?.textAlign|| 'left',
                backGround: style?.backGround|| '#fff',
                color: style?.color|| '#000',
                fontSize: style?.fontSize|| 'inherit',
                fontWeight: style?.fontWeight|| 'none',
            }
        },
        /**
         * Método para aplicar estilos a los botones de la tabla
        */
        getStyleButton(style) {
            return {
                verticalAlign: style?.verticalAlign || 'center',
                textAlign: style?.textAlign|| 'left',
                backGround: style?.backGround|| '#fff',
                color: style?.color|| '#000',
                fontSize: style?.fontSize|| 'inherit',
                fontWeight: style?.fontWeight|| 'none',
            }
        },
        /** 
         * Evento que se emite al padre al pulsar el botón, recoge el nombre del método de manera dinámica
        */
        emitEvent(eventName, item) {
            this.$emit(eventName, item);
        }
    }
}
</script>

<style scoped>
.form-inline select.form-control {
    width: 60px;
}
.td-description{
    cursor: help;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}
</style>